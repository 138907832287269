@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 10px;
  @apply bg-gray-950 font-poppins antialiased;
}

.company-page {
  background: #0c111d center top no-repeat url('../public/assets/images/company/IMG_9209.png');
  background-size: contain;
}

.careers-page {
  background: #090d14 center top no-repeat url('../public/assets/images/carrer-image.png');
  background-size: contain;
}

@screen xs {
  html {
    font-size: calc(1000vw / 375);
  }

  .careers-page {
    background-position-y: 100px;
    background-position-x: 20px;
  }

  body:has(.content:not(.page-transition-enter-active) .company-page) {
    background-size: 80rem;
    background-position-x: -20rem;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  html {
    font-size: calc(1000vw / 1440);
  }
}

.link {
  @apply text-purple-600 underline hover:no-underline;
}

@screen lg {
  .page-transition-enter {
    opacity: 0;
  }

  .page-transition-enter-active {
    opacity: 1;
    transition: opacity 1s;
    transition-delay: 0.4s;
  }

  .page-transition-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .page-transition-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
}

@layer components {
  .container {
    @apply mx-auto w-full max-w-[128rem] px-4xl xs:px-xl;
  }

  .stretch {
    @apply flex flex-1 flex-col;
  }

  .gradient-border {
    border-bottom: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #7f56d9, #a340e0, transparent);
  }

  .gradient-text {
    background: linear-gradient(90deg, #7f56d9 0%, #a340e0 13.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text-gray {
    background: linear-gradient(90deg, #cecfd2 0%, #85888e 0.01%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .tight-container {
    @apply md:px-4xl lg:mx-auto lg:w-full lg:max-w-[76.8rem] xs:px-xl;
  }

  .typography-display-lg {
    @apply text-5xl leading-tight tracking-tight;
  }

  .typography-display-md {
    @apply text-4xl leading-[4.4rem] tracking-normal;
  }

  .typography-text-xl {
    @apply text-xl leading-normal;
  }

  .typography-text-lg {
    @apply text-lg leading-[2.8rem];
  }

  .typography-display-sm {
    @apply text-3xl leading-[3.8rem];
  }

  .typography-text-md {
    @apply text-base leading-normal;
  }

  .typography-text-sm {
    @apply text-sm leading-5;
  }

  .tracer-base {
    padding: 1px;
    background: conic-gradient(
        from calc(var(--r2) - 80deg) at var(--x) 15px,
        transparent 0,
        #7f56d9 20%,
        transparent 25%
      ),
      #231e44;
    border-radius: 999px;
    animation:
      anim-r2 -0.64s linear 3s infinite,
      anim-x -0.64s linear 3s infinite;
  }
}

.home-featured-card {
  position: relative;
  box-shadow: 0.8rem 0.8rem 4.6rem -0.4rem rgba(0, 0, 0, 0.36);
}

.home-featured-card:after,
.home-featured-card:before {
  z-index: 0;
  inset: 0;
  position: absolute;
  content: '';
  pointer-events: none;
  transition: opacity 0.35s ease-in-out;
}

.home-featured-card:after {
  background: radial-gradient(72.14% 72.14% at 50% 116.72%, #3e2b6b 0%, #0d121e 100%);
  opacity: 1;
}

.home-featured-card:before {
  background: radial-gradient(98.47% 98.47% at 50% 98.47%, #3e2b6b 0%, #0d121e 100%);
  opacity: 0;
}

.home-featured-card:hover:after {
  opacity: 0;
}

.home-featured-card:hover:before {
  opacity: 1;
}

.animated-gray-text {
  animation: animatedGrayText 2s infinite;
}

.main-home,
.main-default {
  background-color: #090d14;
}

@keyframes animatedGrayText {
  0% {
    color: theme('colors.gray.400');
  }

  50% {
    color: theme('colors.white');
  }

  100% {
    color: theme('colors.gray.400');
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

.welcome-tracer {
  --width: 284px;
  width: var(--width);
  @apply tracer-base;
}

.how-it-works-tracer {
  --width: 134px;
  width: var(--width);
  @apply tracer-base;
}

@property --r2 {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

@property --x {
  syntax: '<length>';
  inherits: false;
  initial-value: 0;
}

@keyframes anim-r2 {
  0% {
    --r2: 0deg;
  }
  32.82% {
    --r2: 0deg;
  }
  50% {
    --r2: 180deg;
  }
  82.82% {
    --r2: 180deg;
  }
  100% {
    --r2: 360deg;
  }
}

@keyframes anim-x {
  0%,
  82.82%,
  100% {
    --x: 20px;
  }
  32.82%,
  50% {
    --x: calc(var(--width) - 20px);
  }
}
